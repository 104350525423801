import React from 'react'
import { graphql, Link } from 'gatsby'
import Experience from '../components/experience'
import Layout from '../components/layout'

const mailto =
  'mailto:rdrey1@gmail.com,^_^@rainer.codes?body=' +
  encodeURIComponent(
    "Note: `^_^@rainer.codes` or `me@rainer.codes` work, but I've turned off notifications" +
    ' for my self-hosted email server. Please use gmail for urgent email.'
  )

const ExternalLink = props => (
  <a href={props.url} rel="noopener noreferrer" target="_blank">
    <button className="border rounded px-1 text-black font-normal">{props.text}</button>
  </a>
)

const IndexPage = ({ data }) => (
  <Layout>
    <div style={{ display: 'grid' }} className="items-center md:min-h-screen overflow-none">
      <div>
        <div style={{ columnWidth: '20rem', columnCount: 1 }}>
          <div className="leading-normal text-center pt-6 pb-3 pin-x pin-t fixed md:pin-none md:static md:py-auto bg-white">
            <h1 className="text-4xl">Rainer Dreyer</h1>
            <h2 className="leading-tight">Software Engineer</h2>
            {/* <a href="https://stackoverflow.com/users/357578/rdrey">
              <img
                src="https://stackoverflow.com/users/flair/357578.png?theme=clean"
                width="208"
                height="58"
                alt="profile for rdrey at Stack Overflow, Q&amp;A for professional and enthusiast programmers"
                title="profile for rdrey at Stack Overflow, Q&amp;A for professional and enthusiast programmers"
              />
            </a> */}
            <div className="pt-1">
              <ExternalLink text="Mail" url={mailto} />&thinsp;
              <ExternalLink text="GitHub" url="//github.com/rdrey" />&thinsp;
              <ExternalLink text="SO" url="//stackoverflow.com/users/357578/rdrey" />&thinsp;
              <ExternalLink text="Twitter" url="//twitter.com/rdrey1" />&thinsp;
              <ExternalLink text="Keybase" url="//keybase.io/rdrey" />
            </div>
          </div>
          <hr className="mt-6 pt-32 md:hidden overflow-auto" />
          <h2 className="inline">
            <span role="img" aria-label="tools">
              🛠️
            </span>{' '}
          </h2>
          <Link to="/tech/python" className="tech text-lg">
            Python
          </Link>{' '}
          <Link to="/tech/postgresql" className="tech text-lg">
            PostgreSQL
          </Link>{' '}
          <Link to="/tech/ubuntu" className="tech text-lg">
            Ubuntu
          </Link>
          {data.allMdx.edges
            .filter(({ node }) => node.parent.sourceInstanceName === 'xp')
            .map(({ node }) => <Experience key={node.id} exp={node} />)}
        </div>
      </div>
    </div>
  </Layout>
)

export default IndexPage

export const query = graphql`
  query experience {
    allMdx(sort: { fields: [fields___slug], order: DESC }) {
      edges {
        node {
          id
          parent {
            ... on File {
              sourceInstanceName
            }
          }
          frontmatter {
            type
            org
            detail
            tech
            loc
            dates {
              start
              end
              event
            }
          }
          fields {
            slug
            techPages
          }
          excerpt
        }
      }
    }
  }
`
