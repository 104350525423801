import React from 'react'
import { Link } from 'gatsby'

const OrgType = props => {
  if (props.type) return <>{props.type}</>
  return <span className="text-grey-dark">@</span>
}

const Dates = ({ dates, tight }) => {
  if (!dates) return null
  const dash = tight ? <span>&ndash;</span> : <span> &ndash; </span>
  const space = tight ? '' : ' '

  if (dates.event)
    return (
      <span className="pr-4">
        &lang;{space}
        {dates.event}
        {space}&rang;
      </span>
    )
  if (dates.start && dates.end)
    return (
      <span>
        {dates.start}
        {dash}
        {dates.end}
      </span>
    )
  return (
    <span>
      {dates.start}
      {dash}
      <span className="invisible">2018</span>
    </span>
  )
}

class Excerpt extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      show_excerpt: props.show || false,
    }
  }

  render() {
    const show = this.state.show_excerpt
    return (
      <>
        <button
          onClick={() => this.setState({ show_excerpt: !show })}
          className={`hover:underline text-black font-medium ${show && 'underline'}`}
        >
          {this.props.detail}
        </button>
        {' '}&rang;
        <Link to={this.props.link} className="no-underline hover:underline text-black">
          {' '}
          {show ? ` ${this.props.excerpt} ` : ' '}{' '}
        </Link>
      </>
    )
  }
}

function TechComponent(name, tech_pages) {
  if (tech_pages.find(val => val === name.toLowerCase()))
    return (
      <span key={name}>
        <Link to={'/tech/' + name.toLowerCase()} className="tech">
          {name}
        </Link>{' '}
      </span>
    )

  return (
    <span key={name}>
      <span className="tech">{name}</span>{' '}
    </span>
  )
}

const Experience = ({
  exp: {
    frontmatter: { type, org, detail, tech, loc, dates },
    fields: { slug, techPages },
    excerpt,
  },
}) => {
  return (
    <div className="pb-1 border-b">
      <Link
        to={slug}
        className="flex flex-wrap items-baseline no-underline hover:underline text-black"
      >
        <h3 className="leading-loose flex-auto flex-no-shrink">
          <OrgType type={type} /> {org}
        </h3>
        <span className="flex-auto text-right">
          <Dates dates={dates} tight />
        </span>
      </Link>
      <Excerpt show={true} detail={detail} excerpt={excerpt} link={slug} />
      {tech.map(name => TechComponent(name, techPages))}
      &rang; {loc}
    </div>
  )
}

export default Experience
export { OrgType, Dates, TechComponent }
